import React from 'react';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { IconButton, Tooltip } from '@mui/material';

import useDrawerNavigation from '../../../../hooks/useDrawerNavigation';
import styles from './styles';

const ToolbarAdd = () => {
  const { openAddDefaultDrawer } = useDrawerNavigation();

  return (
    <IconButton id="availabilties" onClick={openAddDefaultDrawer}>
      <Tooltip title="Add Availability/Vacation">
        <AddCircleOutlineRoundedIcon sx={styles.icon} />
      </Tooltip>
    </IconButton>
  );
};

export default ToolbarAdd;
