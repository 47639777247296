import {
  addFavourite,
  adminCalendar,
  adminName,
  allNotes,
  getFilterEventsByType,
  getTypeOfUser,
  nameSelector,
  pharmacistCalendar,
  pharmacistHistory,
  pharmacistPDF,
  pharmacistReplacementsWeb,
  pharmacistReports,
  pharmacyAddFavourite,
  pharmacyCalendar,
  pharmacyHistory,
  PharmacyLegendVariant,
  pharmacyNotes,
  pharmacyPdf,
  pharmacyRemoveFavourite,
  pharmacyReplacementsWeb,
  pharmacyReports,
  removeFavourite,
  ToUserTypings,
  TypeOfUser,
  userGeneral,
  userPharmacyInfo,
  userPreferredLanguage,
} from '@pharmaplan/common';

import { useAppSelector } from './useAppSelector';

const useUser = () => {
  const userType = useAppSelector(getTypeOfUser);

  const pharmacistUser = useAppSelector(userGeneral);
  const pharmacyUser = useAppSelector(userPharmacyInfo);
  const adminsCalendar = useAppSelector(adminCalendar);

  const adminUser = useAppSelector(adminName);
  const generalUserName = useAppSelector(nameSelector);

  const activePharmacyCalendar = useAppSelector(pharmacyCalendar);
  const filterEventsByType = useAppSelector(getFilterEventsByType);
  const filteredPharmacyEvents = activePharmacyCalendar?.filter((ev) =>
    filterEventsByType?.includes(ev.type as `${PharmacyLegendVariant}`));

  const activePharmacistCalendar = useAppSelector(pharmacistCalendar);

  const pharmacistEvents = activePharmacistCalendar;

  const activePharmacistReports = useAppSelector(pharmacistReports);
  const activePharmacyReports = useAppSelector(pharmacyReports);

  const pharmacistReportPdf = useAppSelector(pharmacistPDF);
  const pharmacyReportsPdf = useAppSelector(pharmacyPdf);

  const pharmacistReplacement = useAppSelector(pharmacistReplacementsWeb);
  const pharmacyReplacement = useAppSelector(pharmacyReplacementsWeb);

  const pharmacistNotes = useAppSelector(allNotes);
  const pharmacyNote = useAppSelector(pharmacyNotes);

  const pharmacistHist = useAppSelector(pharmacistHistory);
  const pharmacyHist = useAppSelector(pharmacyHistory);

  const preferredLanguage = useAppSelector(userPreferredLanguage);

  const pharmacyFilteredCalendar = filterEventsByType.length > 0 ? filteredPharmacyEvents : activePharmacyCalendar;

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacist]: {
      user: pharmacistUser,
      name: `${pharmacistUser.firstName} ${pharmacistUser.lastName}`,
      calendar: pharmacistEvents,
      reports: activePharmacistReports,
      pdf: pharmacistReportPdf,
      notes: pharmacistNotes,
      replacements: pharmacistReplacement,
      addFavourite,
      removeFavourite,
      history: pharmacistHist,
      legendCalendar: activePharmacistCalendar,
    },
    [TypeOfUser.pharmacy]: {
      user: pharmacyUser,
      name: pharmacyUser.name,
      calendar: pharmacyFilteredCalendar,
      reports: activePharmacyReports,
      pdf: pharmacyReportsPdf,
      notes: pharmacyNote,
      replacements: pharmacyReplacement,
      addFavourite: pharmacyAddFavourite,
      removeFavourite: pharmacyRemoveFavourite,
      history: pharmacyHist,
      legendCalendar: activePharmacyCalendar,
    },
    [TypeOfUser.admin]: {
      calendar: adminsCalendar,
      name: adminUser,
      legendCalendar: adminsCalendar,
    },
    [TypeOfUser.superAdmin]: {
      calendar: adminsCalendar,
      legendCalendar: adminsCalendar,
      name: adminUser,
    },
    [TypeOfUser.pharmacyAdmin]: {
      name: generalUserName,
    },
  });

  const {
    name,
    user,
    calendar,
    reports,
    pdf,
    notes,
    replacements,
    addFavourite: userAddFavourite,
    removeFavourite: userRemoveFavourite,
    history,
    legendCalendar,
  } = userConfig[userType] ?? {};

  return {
    name,
    userInfo: user,
    calendar,
    reports,
    pdf,
    notes,
    replacements,
    userType,
    preferredLanguage,
    addFavourite: userAddFavourite,
    removeFavourite: userRemoveFavourite,
    history,
    legendCalendar,
  };
};

export default useUser;
