import React, { FC, useEffect, useState } from 'react';

import { Box } from '@mui/system';

import { groupBy } from 'rambda';
import {
  pharmacistCalendar,
  PharmacistLegendVariant,
} from '@pharmaplan/common';

import { DateTime } from 'luxon';
import {
  setButtons,
  setDrawerContentPadding,
} from '../../actions/drawerActions';
import useAvailabilityVacation from '../../hooks/useAvailabilityVacation';
import { IPendingTabConfig } from '../Admin/PendingTabs/PendingTabs';
import { AvailabilityVacationTabs } from '../../helpers/Constants';
import useSelectedTabs from '../../hooks/Admin/useSelectedTabs';
import MultiSelectCalendar from '../common/MultiSelectCalendar';
import AddAvailability from '../Availability/AddAvailability';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import CustomCheckbox from '../common/CustomCheckbox';
import PendingTabs from '../Admin/PendingTabs';
import ICalendar from '../../types/ICalendar';
import strings from '../../localization';

import styles from './styles';

interface IAvailabilityVacation {
  date: string;
}

const { vacation, availability } = AvailabilityVacationTabs;
const disableVacationCriteria = [PharmacistLegendVariant.booked];
const disableAvailabilityCriteria = [
  PharmacistLegendVariant.requested,
  PharmacistLegendVariant.booked,
];

const AvailabilityVacation: FC<IAvailabilityVacation> = ({ date }) => {
  const dispatch = useAppDispatch();

  const [showMultiple, setShowMultiple] = useState(false);
  const { selectedTabs, handleTabClick } = useSelectedTabs(availability);
  const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
  const calendar = useAppSelector(pharmacistCalendar);

  const isAvailability = selectedTabs === availability;

  const segmentConfig: IPendingTabConfig[] = [
    {
      key: availability,
      tabName: strings.availability,
      onClick: handleTabClick,
    },
    {
      key: vacation,
      tabName: strings.vacation,
      onClick: handleTabClick,
    },
  ];

  const { handleAvailabilitiesSave, handleVacationsSave } = useAvailabilityVacation(selectedDates, showMultiple);

  useEffect(() => {
    if (showMultiple) {
      dispatch(
        setButtons({
          show: true,
          primaryButton: {
            label: strings.create,
            onClick:
              selectedTabs === vacation
                ? handleVacationsSave
                : handleAvailabilitiesSave,
          },
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMultiple, selectedTabs, selectedDates]);

  useEffect(() => {
    dispatch(setDrawerContentPadding('0px'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () =>
    setShowMultiple((prev) =>
      !prev);

  const dateMapper = (inDate: DateTime) => {
    const groupByStartDate = groupBy(
      (e: ICalendar) =>
        e.startDate.split('T')[0],
    );

    const groupedEvents = groupByStartDate(calendar);
    const groupedTypes = Object.keys(groupedEvents).reduce((acc: any, curr) => {
      acc[curr] = groupedEvents[curr].map((item) =>
        item.type);

      return acc;
    }, {});

    const onlyDate = inDate.toISO({ includeOffset: false })?.split('T')[0] ?? '';
    const criteria = isAvailability
      ? disableAvailabilityCriteria
      : disableVacationCriteria;

    const disabled = groupedTypes?.[onlyDate]?.some(
      (item: PharmacistLegendVariant) =>
        criteria.includes(item),
    );

    return disabled;
  };

  return (
    <Box sx={styles.container}>
      <PendingTabs
        selectedTab={selectedTabs}
        tabConfig={segmentConfig}
        rootStyle={styles.root}
        buttonStyle={styles.navButton}
        showHeader={false}
      />
      {isAvailability && (
        <CustomCheckbox
          customClass={styles.selectDates}
          label={strings.selectMultipleDates}
          id="multiple"
          name="multiple"
          value={showMultiple}
          handleChange={handleChange}
        />
      )}
      {(showMultiple || !isAvailability) && (
        <MultiSelectCalendar
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          dateMapper={dateMapper}
        />
      )}
      {isAvailability && (
        <AddAvailability showMultiple={showMultiple} date={date} />
      )}
    </Box>
  );
};

export default AvailabilityVacation;
