import React, { FC } from 'react';

import { Box } from '@mui/material';

import AddUpdateEvents from '../../../common/AddUpdateEvents/AddUpdateEvents';
import NotesField from '../../../common/NotesField/NotesField';
import AllowanceWorkshift from '../AllowanceWorkshift';

import classes from './styles';
import useAddWorkshift from '../../../../hooks/workshift/useAddWorkshift';

interface IAdd {
  date: string;
}

const Add: FC<IAdd> = ({ date }) => {
  const { container } = classes;

  const { isSelfService, formik, setUserWorkTimings } = useAddWorkshift(date);

  return (
    <Box sx={container}>
      <AddUpdateEvents
        formik={formik}
        setUserWorkTimings={setUserWorkTimings}
        isSeries
      />
      {isSelfService && <AllowanceWorkshift formik={formik} />}
      <NotesField formik={formik} />
    </Box>
  );
};

export default Add;
