import React, { FC } from 'react';

import { Box, Tooltip } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import styles from './styles';
import strings from '../../../../localization';

interface IWatchlisted {
  inCalendar?: boolean;
}

const Watchlisted: FC<IWatchlisted> = ({ inCalendar = false }) => {
  const tooltipTitle = inCalendar ? null : strings.watchlist;

  return (
    <Box id="watchlist" sx={styles.container} component="div">
      <Tooltip title={tooltipTitle}>
        <BookmarkIcon sx={inCalendar ? styles.calendarIcon : styles.icon} />
      </Tooltip>
    </Box>
  );
};

export default Watchlisted;
