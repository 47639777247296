import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
  getPharmacistHistory,
  getPharmacyHistory,
  pharmacistActions,
  pharmacyActions,
  resetStatus,
  successOrSelector,
  successSelector,
  ToUserTypings,
  TypeOfUser,
  userPreferredTimeFormat,
} from '@pharmaplan/common';

import DynamicTable from '../../DynamicTable';
import AdsContainer from '../../common/AdsContainer';
import OutcomeModal from '../../Modals/OutcomeModal';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useUser from '../../../hooks/useUser';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { NavType, OutcomeModalTypes } from '../../../helpers/Constants';
import ResponsiveClasses from '../../../theme/ResponsiveClasses';
import { setDialog } from '../../../reducers/dialogReducer';
import strings from '../../../localization';

import { pharmacyHistoryMapper, pharmacistHistoryMapper } from '../helpers';
import PharmacistRating from '../PharmacistRating/PharmacistRating';

const History = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  const { state } = useLocation();
  const { id, name } = state;
  const { history, userType } = useUser();
  const { data, totalCount } = history;
  const isPharmacist = userType === TypeOfUser.pharmacist;
  const timeFormat = useAppSelector(userPreferredTimeFormat);

  const loadSuccess = useAppSelector((st) =>
    successOrSelector([pharmacistActions.history, pharmacyActions.history], st));

  const ratingSuccess = useAppSelector((st) =>
    successSelector([pharmacyActions.rate], st));

  const label = isPharmacist ? strings.pharmacy : strings.pharmacist;

  const api = (inId: string, inPage: number) => {
    if (isPharmacist) {
      return dispatch(
        getPharmacistHistory({ pharmacyId: inId, paging: { page: inPage } }),
      );
    }
    return dispatch(
      getPharmacyHistory({ pharmacistId: inId, paging: { page: inPage } }),
    );
  };

  const OpenModal = (bookingId: string) => {
    dispatch(
      setDialog({
        showCloseButton: false,
        Component: (
          <PharmacistRating
            pharmacistId={id}
            pharmacistName={name}
            bookingId={bookingId}
          />
        ),
        heading: {
          title: '',
        },
        maxWidth: 'sm',
      }),
    );
  };

  const userConfig = ToUserTypings({
    [TypeOfUser.pharmacy]: {
      headers: [
        { key: '0', label: '#' },
        { key: '1', label: strings.shiftCode },
        { key: '2', label: strings.date },
        { key: '3', label: strings.time },
        { key: '6', label: strings.action },
      ],
      rows: pharmacyHistoryMapper(data, timeFormat, OpenModal, page),
    },
    [TypeOfUser.pharmacist]: {
      headers: [
        { key: '0', label: '#' },
        { key: '1', label: strings.shiftCode },
        { key: '2', label: strings.date },
        { key: '3', label: strings.time },
      ],
      rows: pharmacistHistoryMapper(data, timeFormat, page),
    },
  });

  const Table = {
    title: `${strings.formatString(strings.myUserHistory, label)} [${name}]`,

    headers: userConfig[userType].headers,
    headerBar: [
      {
        Component: <div />,
        key: '1',
      },
    ],
    rows: userConfig[userType].rows,
  };

  useEffect(() => {
    api(id, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ratingSuccess) {
      dispatch(
        setDialog({
          Component: (
            <OutcomeModal
              type={OutcomeModalTypes.submitSuccess}
              message={strings.ratingSuccessful}
            />
          ),
          heading: { title: '' },
          showCloseButton: true,
        }),
      );
      dispatch(resetStatus([pharmacyActions.rate]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingSuccess]);

  const handlePagination = (_: unknown, selectedPage: number) => {
    const goToPage = selectedPage + 1;

    setPage(goToPage);
    api(id, goToPage);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={10}>
        <DynamicTable
          table={Table}
          loadSuccess={loadSuccess}
          totalCount={totalCount}
          page={page}
          type={NavType.history}
          handlePagination={handlePagination}
        />
      </Grid>
      <Grid item xs={2} sx={ResponsiveClasses.hideOnLg}>
        <AdsContainer />
      </Grid>
    </Grid>
  );
};

export default History;
