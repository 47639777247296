import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { getAllPharmacistAvailabilies, logout } from '../actions';
import { setMultipleAvailabilities } from '../actions/calendarActions';
import {
  AvailabilityTypes,
  CalendarStateKey,
  CalendarView,
  DashboardType,
  PharmacyLegendVariant,
  TypeOfUser,
} from '../helpers/Constants';

import { IAdminMultipleAvailabilityCalendar } from '../models';

export interface ICalendarReducerState {
  isMap: boolean;
  filter: any;
  activeDate: string;
  activeTypeOfUser: `${TypeOfUser}`;
  filterEventsByType: Array<`${PharmacyLegendVariant}`>;
  mapDate: any;
  enableMobileAdd: boolean;
  enableMultipleAdd: boolean;
  calendarDate: any;
  activeView: `${CalendarView}`;
  activeWebView: CalendarStateKey;
  activeDashboard: `${DashboardType}`;
  focusedDate: string | null;
  weekStartDate: string | null;
  multipleAvailabilities: IAdminMultipleAvailabilityCalendar[];
  multipleEventType: AvailabilityTypes;
}

const currentDate = DateTime.now().toISO({ includeOffset: false }) ?? '';

export const getCalDates = (date = new Date()) => {
  const day = 60 * 60 * 24 * 1000;
  const week = day * 7;

  const interStartDate = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1
  ).getTime();
  const interEndDate = new Date(
    date.getFullYear(),
    date.getMonth() + 2,
    0
  ).getTime();

  const startDate = new Date(interStartDate - week);
  const endDate = new Date(interEndDate + week);

  return { startDate, endDate };
};

export const getMapDates = (date = new Date()) => {
  const startDate = DateTime.fromJSDate(date)
    .startOf('month')
    .toISO({ includeOffset: false });
  const endDate = DateTime.fromJSDate(date)
    .endOf('month')
    .toISO({ includeOffset: false });

  return { startDate, endDate };
};
const { startDate, endDate } = getCalDates();
const { startDate: mapStartDate, endDate: mapEndDate } = getMapDates();

export const calendarReducerInitialState: ICalendarReducerState = {
  multipleAvailabilities: [],
  isMap: false,
  enableMobileAdd: false,
  enableMultipleAdd: false,
  activeDate: currentDate,
  activeTypeOfUser: TypeOfUser.pharmacist,
  focusedDate: null,
  weekStartDate: currentDate,
  filterEventsByType: [],
  activeView: CalendarView.monthView,
  activeWebView: CalendarStateKey.month,
  activeDashboard: DashboardType.calendar,
  mapDate: {
    startDate: mapStartDate,
    endDate: mapEndDate,
  },
  calendarDate: {
    startDate: startDate,
    endDate: endDate,
  },
  filter: {
    banners: [],
    softwares: [],
    cities: [],
    minHourRate: '',
    weekVolume: {
      min: 0,
      max: 0,
    },
    weekendVolume: {
      min: 0,
      max: 0,
    },
  },
  multipleEventType: AvailabilityTypes.tempAvailability,
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: calendarReducerInitialState,
  reducers: {
    setIsMap(state, { payload }: { payload: boolean }) {
      state.isMap = payload;
    },
    updateFilter(state, { payload }: { payload: any }) {
      state.filter = payload;
    },
    updateCalendarDate(state, { payload }) {
      state.calendarDate = payload;
    },
    updateMapDate(state, { payload }) {
      state.mapDate = payload;
    },
    setFocusedDate(state, { payload }) {
      state.focusedDate = payload;
    },
    setEnableMobileAdd(state, { payload }) {
      state.enableMobileAdd = payload;
      // IF MOBILE IS ON DASHBOARD, DISABLE MULTIPLE ADD
      if (!payload) {
        state.enableMultipleAdd = false;
      }
    },
    setEnableMultipleAdd(state, { payload }) {
      state.enableMultipleAdd = payload;
    },
    setActiveDate(state, { payload }: { payload: string }) {
      state.activeDate = payload;
      const newCalDate = getCalDates(new Date(payload));
      const newMapDate = getMapDates(new Date(payload));

      state.calendarDate.startDate = newCalDate.startDate;
      state.calendarDate.endDate = newCalDate.endDate;

      state.mapDate.startDate = newMapDate.startDate;
      state.mapDate.endDate = newMapDate.endDate;
    },
    setActiveView(state, { payload }: { payload: `${CalendarView}` }) {
      state.activeView = payload;
    },
    setActiveWebView(state, { payload }: { payload: CalendarStateKey }) {
      state.activeWebView = payload;
    },
    setActiveDashboard(state, { payload }: { payload: `${DashboardType}` }) {
      state.activeDashboard = payload;
    },
    setActiveUser(state, { payload }: { payload: TypeOfUser }) {
      state.activeTypeOfUser = payload;
    },
    setFilterEventsByType(
      state,
      { payload }: { payload: Array<`${PharmacyLegendVariant}`> }
    ) {
      state.filterEventsByType = payload;
    },
    setWeekStartDate(state, { payload }: { payload: string }) {
      state.weekStartDate = payload;
    },
    setMultipleEventType(state, { payload }: { payload: AvailabilityTypes }) {
      state.multipleEventType = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        return calendarReducerInitialState;
      })
      .addCase(getAllPharmacistAvailabilies.fulfilled, (state, action) => {
        state.multipleAvailabilities = action.payload;
      })
      .addCase(setMultipleAvailabilities, (state, action) => {
        state.multipleAvailabilities = action.payload;
      });
  },
});

const { reducer, actions } = calendarSlice;
export const {
  setIsMap,
  updateCalendarDate,
  updateFilter,
  setActiveDate,
  setActiveUser,
  updateMapDate,
  setEnableMobileAdd,
  setFocusedDate,
  setFilterEventsByType,
  setEnableMultipleAdd,
  setWeekStartDate,
  setActiveView,
  setActiveDashboard,
  setActiveWebView,
  setMultipleEventType,
} = actions;
export default reducer;
