import React, { FC } from 'react';

import { GroupChannelListHeader } from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader';
import Icon, { IconTypes } from '@sendbird/uikit-react/ui/Icon';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { HelpTypes } from '@pharmaplan/common';

import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setDialog } from '../../../../reducers/dialogReducer';
import strings from '../../../../localization';

import GroupChannelListTitle from '../GroupChannelListTitle';
import CreateGroupDialog from '../CreateGroupDialog';
import styles from './styles';

interface IGroupHeader {
  setSegment: React.Dispatch<React.SetStateAction<string>>;
  segment: string;
  unreadSegments: {
    11: boolean;
    10: boolean;
  };
}

const segments: Array<{
  key: HelpTypes.pharmacist | HelpTypes.pharmacy;
  label: string;
}> = [
  { key: HelpTypes.pharmacist, label: strings.pharmacists },
  { key: HelpTypes.pharmacy, label: strings.pharmacies },
];

const GroupHeader: FC<IGroupHeader> = ({
  segment,
  setSegment,
  unreadSegments,
}) => {
  const dispatch = useAppDispatch();

  const showDialog = () => {
    dispatch(
      setDialog({
        showCloseButton: true,
        heading: {
          title: strings.newChat,
        },
        maxWidth: 'sm',
        Component: <CreateGroupDialog />,
      }),
    );
  };

  const handleSegmentClick = (key: string) =>
    () => {
      setSegment(key);
    };

  return (
    <Box>
      <GroupChannelListHeader
        renderMiddle={() =>
          <GroupChannelListTitle />}
        renderIconButton={() =>
          (
            <Box onClick={showDialog} sx={styles.create}>
              <Icon type={IconTypes.CREATE} fillColor="PRIMARY" />
            </Box>
          )}
      />
      <Box sx={styles.segment}>
        {segments.map((item) => {
          const { key, label } = item;
          const isActive = key === segment;

          return (
            <Button
              key={key}
              variant="text"
              sx={[
                styles.segmentButtons,
                isActive ? styles.active : styles.inActive,
              ]}
              onClick={handleSegmentClick(key)}
            >
              <Typography>{label}</Typography>
              {!!unreadSegments?.[key] && (
                <Box sx={[styles.shakingCount, styles.count]} />
              )}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default GroupHeader;
