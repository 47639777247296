import React, { useCallback, useEffect } from 'react';

import { Box } from '@mui/material';
import { FormikProps } from 'formik';

import {
  TypeOfUser,
  getTypeOfUser,
  reset,
  getRecurrence,
} from '@pharmaplan/common';

import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import CustomCheckbox from '../CustomCheckbox';
import CustomRecurrence from '../../Modals/CustomRecurrence';
import CustomDropdown from '../CustomDropdown';
import DateTimeRange from '../DateTimeRange';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAdmin from '../../../hooks/useAdmin';
import useSelfService from '../../../hooks/useSelfService';
import { resetDialog, setDialog } from '../../../reducers/dialogReducer';
import {
  DurationTypes,
  InvertedRepeatMap,
  RecurrenceTypes,
  ReplacementType,
  serverDateKey,
} from '../../../helpers/Constants';
import { getZeroHourDate } from '../../../helpers/Functions';
import strings from '../../../localization';
import genericClasses from '../../../theme/GenericClasses';

import classes from './style';

interface IAddUpdateEvents {
  formik: FormikProps<any>;
  setUserWorkTimings: () => void;
  isSeries: boolean;
  isAvailability?: boolean;
  showRecurrences?: boolean;
  disableAll?: boolean;
}

const AddUpdateEvents = ({
  formik,
  setUserWorkTimings,
  isSeries,
  isAvailability,
  showRecurrences = true,
  disableAll,
}: IAddUpdateEvents) => {
  const dispatch = useAppDispatch();
  const { isSelfService } = useSelfService();
  const { isAdmin } = useAdmin();

  const userType = useAppSelector(getTypeOfUser);

  // ITEM DISABLED ONLY WHEN UPDATING IN SELF SERVICE
  const disabledSelfService = (!isAvailability && isSelfService) || disableAll;

  // ITEMS DISABLED ONLY WHEN IT IS SINGLE RECURRENCE UPDATE
  const disabledRecurrence = !isSeries || disableAll;

  const isPharmacist = userType === TypeOfUser.pharmacist;
  const isPharmacy = userType === TypeOfUser.pharmacy;

  const recurrence = useAppSelector(getRecurrence);

  const enableSetRecurrenceDialog = useCallback(
    (currentRec: DurationTypes) => {
      dispatch(
        setDialog({
          Component: (
            <CustomRecurrence
              date={formik.values.selectedDate}
              currentRecurrence={currentRec}
              primaryBtnFunction={() => {
                dispatch(resetDialog());
              }}
            />
          ),
          showCloseButton: true,
          heading: {
            title: strings.customRecurrence,
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.selectedDate],
  );

  const handleAllDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('allday', e.target.checked);
    if (e.target.checked) {
      setUserWorkTimings();
    }
  };

  const handleNegativeDates = () => {
    formik.setFieldValue('endTime', null);
  };
  const serverToday = getZeroHourDate(
    sessionStorage.getItem(serverDateKey) ?? '',
  );

  useEffect(() => {
    formik.setFieldValue(
      'recurrence',
      InvertedRepeatMap[recurrence.repeatEvery],
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrence.repeatEvery]);

  useEffect(
    () =>
      () => {
        dispatch(reset());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },
    [],
  );

  return (
    <Box component="div">
      {isPharmacy && (
        <CustomDropdown
          formik={formik}
          label={strings.typeReplacement}
          name="replacement"
          disabled
          menuItems={ReplacementType()}
          customClass={genericClasses.borderNoneTextField}
        />
      )}

      {!isAdmin && (
        <CustomDatePicker
          formik={formik}
          minDate={serverToday}
          disabled={disabledSelfService}
          label={strings.selectedDate}
          name="selectedDate"
          readOnly
        />
      )}

      {isPharmacist && (
        <CustomCheckbox
          id="allday"
          name="allday"
          value={formik.values.allday}
          label={strings.allday}
          customClass={classes.checkbox}
          handleChange={handleAllDay}
          labelClass={classes.checkboxLabel}
          disabled={disableAll}
        />
      )}

      <Box component="div" sx={classes.timeContainer}>
        <DateTimeRange
          formik={formik}
          startName="startTime"
          endName="endTime"
          customStartHandler={handleNegativeDates}
          allowValidOnly={false}
          startPlaceholder="00:00"
          endPlaceholder="00:00"
          startNameLabel={strings.startTime}
          endNameLabel={strings.endTime}
          horizontal={false}
          removeMargin
          disabled={disableAll}
        />
      </Box>

      {showRecurrences && (
        <Box component="div" sx={classes.recurrenceContainer}>
          <CustomDropdown
            name="recurrence"
            isRecurrence
            disabled={disabledRecurrence}
            label={strings.recurrences}
            formik={formik}
            onClickMenuItem={(val) => {
              if (val !== 'notRepeat') {
                enableSetRecurrenceDialog(val as DurationTypes);
              }
            }}
            menuItems={RecurrenceTypes()}
          />
        </Box>
      )}
    </Box>
  );
};
export default AddUpdateEvents;
