import React, { FC, useEffect } from 'react';
import {
  getPharmacistReplacementDetails,
  pharmacistReplacementDetails,
  pharmacyActions,
} from '@pharmaplan/common';

import SoftwareExpertise from '../SelfService/SoftwareExpertise';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import PharmacistCard from '../SelfService/PharmacistCard';
import { ScreenTypes } from '../../helpers/Constants';
import { setFooterShow } from '../../actions/drawerActions';

interface IPharmacistReplacementProfile {
  pharmacistId: string;
}
const success = pharmacyActions.pharmacistReplacementDetails;

const PharmacistReplacementProfile: FC<IPharmacistReplacementProfile> = ({
  pharmacistId,
}) => {
  const dispatch = useAppDispatch();
  const pharmacistDetails = useAppSelector(pharmacistReplacementDetails);

  useEffect(() => {
    dispatch(getPharmacistReplacementDetails(pharmacistId));
    dispatch(setFooterShow(false));
  }, []);

  return (
    <>
      <PharmacistCard
        successAction={success}
        pharmacistDetails={pharmacistDetails}
        screenType={ScreenTypes.pharmacistProfile}
      />
      <SoftwareExpertise
        successAction={success}
        softwareDetails={pharmacistDetails.softwares}
      />
    </>
  );
};

export default PharmacistReplacementProfile;
