import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import PharmacistController from '../controllers/PharmacistController';
import {
  ICreateAvailabilityRequestParams,
  IUpdateAvailabilitRequestParams,
} from '../controllers/types';
import { PharmacistLegendVariant } from '../helpers/Constants';

import {
  ICalendarFilter,
  ICancelBooking,
  IHistoryApiParams,
  IHistoryParams,
  IIncomeDetailsParams,
  IIncomeReportParams,
  IPharmacistLocation,
  IPreSubmitVacationsParams,
  IReportFilterParams,
  IReportOmitAvailability,
  IReportsPDFParams,
  IRequestBookingParams,
  IUpdateVacationsParams,
  IVacationsParams,
  IWorkshiftParams,
} from '../models/PharmacistModels';
import { statusDispatch } from './globalActions';

export const pharmacistActions = {
  picture: 'pharmacist/picture',
  uploadResume: 'pharmacist/resume/upload',
  deleteResume: 'pharmacist/resume/delete',
  resume: 'pharmacist/resume',
  deletePicture: 'pharmacist/picture/delete',
  pharmacistMap: 'pharmacist/map/events',
  pharmacistCalendar: 'pharmacist/calendar',
  pharmacistFilterValues: 'pharmacist/filterValues',
  createAvailability: 'pharmacist/availabilities/create',
  updateAvailability: 'pharmacist/availabilities/update',
  deleteAvailabilities: 'pharmacist/deleteAvailabilities',
  requestBooking: 'pharmacist/requestBooking',
  cancelBooking: 'pharmacist/cancelBooking',
  cancelRequestedBooking: 'pharmacist/cancelRequestedBooking',
  deletePharmacistPicture: 'delete/pharmacist/picture',
  reports: 'pharmacist/reports',
  reportsPage: 'pharmacist/reports/page',
  replacements: 'pharmacist/replacements',
  history: 'pharmacist/history',
  historyPagination: 'pharmacist/history/pagination',
  addFavourite: 'pharmacist/addFavourite',
  removeFavourite: 'pharmacist/removeFavourite',
  pharmacyNames: 'pharmacist/pharmacyNames',
  pictureUpload: 'pharmacist/uploadPicture',
  createNote: 'pharmacist/createNote',
  updateNote: 'pharmacist/updateNote',
  deleteNote: 'pharmacist/deleteNote',
  getNotes: 'pharmacist/notes',
  getAvailability: 'pharmacist/availability',
  getRegions: 'pharmacist/regions',
  updateRegions: 'pharmacist/regions/update',
  createRegions: 'pharmacist/regions/create',
  deleteRegions: 'pharmacist/regions/delete',
  getReplacementPDF: 'pharmacist/get/replacement/pdf',
  getReportsPDF: 'pharmacist/get/reports/pdf',
  getReportsFilterInfo: 'pharmacist/reports/filter/info',
  getPostedShifts: 'pharmacist/get/posted',
  acceptUpdatedTimings: 'pharmacist/accept/updatedTimings',
  rejectUpdatedTimings: 'pharmacist/reject/updatedTimings',
  selfCancelBooking: 'pharmacist/self/bookings/cancel',
  getCounterOffer: 'pharmacist/get/counteroffer',
  replacementsWeb: 'pharmacist/replacements/web',
  resetPostedShifts: 'pharmacist/reset/posted',
  preRequest: 'pharmacist/request/pre',
  resetPreRequest: 'pharmacist/request/reset',
  incomeReports: 'pharmacist/income/reports',
  incomeReportsPage: 'pharmacist/incomeReports/page',
  incomeDetails: 'pharmacist/income/details',
  incomeDetailsPage: 'pharmacist/incomeDetails/page',
  getPharmacistEvent: 'pharmacist/get/pharmacistEvent',
  setPharmacistLocation: 'pharmacist/set/location',
  watchlist: 'pharmacist/watchlist',
  deleteWatchlist: 'pharmacist/delete/watchlist',
  preSubmitVacations: 'pharmacist/vacations/preSubmit',
  vacations: 'pharmacist/vacations',
  getVacations: 'pharmacist/get/vacations',
  deleteVacations: 'pharmacist/delete/vacations',
  updateVacations: 'pharmacist/update/vacations',
  preSubmitAvailabilities: 'pharmacist/availabilities/preSubmit',
};

export const getAvailability = createAsyncThunk(
  pharmacistActions.getAvailability,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getAvailability(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistPicture = createAsyncThunk(
  pharmacistActions.picture,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistPicture();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistFilterValues = createAsyncThunk(
  pharmacistActions.pharmacistFilterValues,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistFilterValues();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistCalendar = createAsyncThunk(
  pharmacistActions.pharmacistCalendar,
  async (filters: ICalendarFilter, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistCalendar(filters);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPostedShifts = createAsyncThunk(
  pharmacistActions.getPostedShifts,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistPostedShifts(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createAvailability = createAsyncThunk(
  pharmacistActions.createAvailability,
  async (params: ICreateAvailabilityRequestParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.createAvailability(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAvailability = createAsyncThunk(
  pharmacistActions.updateAvailability,
  async (params: IUpdateAvailabilitRequestParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.updateAvailability(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAvailability = createAsyncThunk(
  pharmacistActions.deleteAvailabilities,
  async (
    deleteArr: { id?: string; recurrenceId?: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await PharmacistController.deleteAvailability(
        deleteArr.id,
        deleteArr.recurrenceId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const requestBooking = createAsyncThunk(
  pharmacistActions.requestBooking,
  async (params: IRequestBookingParams | string, { rejectWithValue }) => {
    //To avoid this API Request (CounterOffer) effecting previous code.
    const isObject = typeof params === 'object';
    try {
      const resp = await PharmacistController.requestBooking(
        isObject ? params : { workshiftid: params }
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelBooking = createAsyncThunk(
  pharmacistActions.cancelBooking,
  async (params: ICancelBooking, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.cancelBooking(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelRequestedBooking = createAsyncThunk(
  pharmacistActions.cancelRequestedBooking,
  async (bookingIdArr: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.cancelRequestedBooking(
        bookingIdArr
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistReports = createAsyncThunk(
  pharmacistActions.reports,
  async (params: IReportFilterParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.reports(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistReportsPage = createAsyncThunk(
  pharmacistActions.reportsPage,
  async (params: IReportFilterParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.reports(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistReplacements = createAsyncThunk(
  pharmacistActions.replacements,
  async (page: number, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.replacements(page);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistReplacementsWeb = createAsyncThunk(
  pharmacistActions.replacementsWeb,
  async (page: number, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.replacements(page);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistHistory = createAsyncThunk(
  pharmacistActions.history,
  async (params: IHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.history(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistHistoryPagination = createAsyncThunk(
  pharmacistActions.historyPagination,
  async (params: IHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.history(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addFavourite = createAsyncThunk(
  pharmacistActions.addFavourite,
  async (params: IHistoryParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.addFavourite(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeFavourite = createAsyncThunk(
  pharmacistActions.removeFavourite,
  async (params: IHistoryParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.removeFavourite(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllPharmacyNames = createAsyncThunk(
  pharmacistActions.pharmacyNames,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacyNames();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pictureUpload = createAsyncThunk(
  pharmacistActions.pictureUpload,
  async (form: any, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pictureUpload(form);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createNote = createAsyncThunk(
  pharmacistActions.createNote,
  async (param: { pharmacyId: string; note: string }, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.createNote(
        param.pharmacyId,
        param.note
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotes = createAsyncThunk(
  pharmacistActions.getNotes,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.allNotes(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateNote = createAsyncThunk(
  pharmacistActions.updateNote,
  async (param: { noteId: string; note: string }, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.updateNote(
        param.noteId,
        param.note
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteNote = createAsyncThunk(
  pharmacistActions.deleteNote,
  async (noteId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deleteNote(noteId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistMap = createAsyncThunk(
  pharmacistActions.pharmacistMap,
  async (filter: ICalendarFilter, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistMap(filter);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistResume = createAsyncThunk(
  pharmacistActions.resume,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistResume();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReplacementPDF = createAsyncThunk(
  pharmacistActions.getReplacementPDF,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getReplacementsPDF();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportsPDF = createAsyncThunk(
  pharmacistActions.getReportsPDF,
  async (params: IReportsPDFParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getReportsPdf(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadResume = createAsyncThunk(
  pharmacistActions.uploadResume,
  async (form: { path: string; document: Buffer }, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.resumeUpload(form);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteResume = createAsyncThunk(
  pharmacistActions.deleteResume,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deleteResume();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const deletePicture = createAsyncThunk(
  pharmacistActions.deletePharmacistPicture,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deletePicture();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRegions = createAsyncThunk(
  pharmacistActions.getRegions,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getRegions();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateRegions = createAsyncThunk(
  pharmacistActions.updateRegions,
  async (regionIds: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.updateRegions(regionIds);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addRegion = createAsyncThunk(
  pharmacistActions.createRegions,
  async (regionId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.createRegion(regionId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteRegion = createAsyncThunk(
  pharmacistActions.deleteRegions,
  async (regionId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deleteRegion(regionId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportsFilterInfo = createAsyncThunk(
  pharmacistActions.getReportsFilterInfo,
  async (type: IReportOmitAvailability, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.reportsFilterInfo(type);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCounterOffer = createAsyncThunk(
  pharmacistActions.getCounterOffer,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getCounterOfferDetails(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const acceptUpdatedTimings = createAsyncThunk(
  pharmacistActions.acceptUpdatedTimings,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.acceptUpdatedTimings(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const rejectUpdatedTimings = createAsyncThunk(
  pharmacistActions.rejectUpdatedTimings,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.rejectUpdatedTimings(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const selfPharmacistCancelBookings = createAsyncThunk(
  pharmacistActions.selfCancelBooking,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.selfPharmacistCancelBooking(
        bookingId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPostedShifts = createAction(
  pharmacistActions.resetPostedShifts
);

export const preRequest = createAsyncThunk(
  pharmacistActions.preRequest,
  async (workshiftid: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.preRequest(workshiftid);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeReports = createAsyncThunk(
  pharmacistActions.incomeReports,
  async (params: IIncomeReportParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.income(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeReportsPage = createAsyncThunk(
  pharmacistActions.incomeReportsPage,
  async (params: IIncomeReportParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.income(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeDetails = createAsyncThunk(
  pharmacistActions.incomeDetails,
  async (params: IIncomeDetailsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.incomeDetails(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeDetailsPage = createAsyncThunk(
  pharmacistActions.incomeDetailsPage,
  async (params: IIncomeDetailsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.incomeDetails(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEventWithLocation = createAsyncThunk(
  pharmacistActions.getPharmacistEvent,
  async (params: IWorkshiftParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getEvent(
        params
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const watchlist = createAsyncThunk(
  pharmacistActions.watchlist,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.watchlist(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setPharmacistLocation = createAction<IPharmacistLocation>(
  pharmacistActions.setPharmacistLocation
);

export const deleteWatchlist = createAsyncThunk(
  pharmacistActions.deleteWatchlist,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deleteWatchlist(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const preSubmitVacations = createAsyncThunk(
  pharmacistActions.preSubmitVacations,
  async (params: IPreSubmitVacationsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.preSubmitVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pharmacistVacations = createAsyncThunk(
  pharmacistActions.vacations,
  async (params: IVacationsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.pharmacistVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistVacations = createAsyncThunk(
  pharmacistActions.getVacations,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.getPharmacistVacations(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePharmacistVacations = createAsyncThunk(
  pharmacistActions.deleteVacations,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.deletePharmacistVacations(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePharmacistVacations = createAsyncThunk(
  pharmacistActions.updateVacations,
  async (params: IUpdateVacationsParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.updatePharmacistVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const preSubmitAvailabilities = createAsyncThunk(
  pharmacistActions.preSubmitAvailabilities,
  async (params: ICreateAvailabilityRequestParams, { rejectWithValue }) => {
    try {
      const resp = await PharmacistController.preSubmitAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPreRequest = createAction(pharmacistActions.resetPreRequest);
