import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Joyride from 'react-joyride';

import {
  getActiveDashboard,
  DashboardType,
  TypeOfUser,
  getTypeOfUser,
  startTour,
} from '@pharmaplan/common';

import AdsContainer from '../common/AdsContainer';
import ContentContainer from '../common/ContentContainer';
import LegendContainer from '../common/LegendContainer';

import { useAppSelector } from '../../hooks/useAppSelector';
import useUser from '../../hooks/useUser';
import useGuidelines from '../../hooks/useGuidelines';
import useContinueCreation from '../../hooks/useContinueCreation';
import strings from '../../localization';
import ResponsiveClasses from '../../theme/ResponsiveClasses';
import {
  journeyStepsPharmacist,
  journeyStepsPharmacy,
} from '../../helpers/journeySteps';
import { BreakPoints } from '../../helpers/Constants';

import GuideTooltip from '../GuideTooltip/GuideTooltip';
import CustomDrawer from '../Drawer/Drawer';

import MainCalendar from './MainCalendar';
import PharmacistMap from './PharmacistMap';
import Toolbar from './Toolbar';
import classes from './style';
import useDashboard from '../../hooks/useDashboard';
import useForegroundNotifications from '../../hooks/PushNotifications/useForegroundNotifications';

export const DashboardComponents = {
  [DashboardType.calendar]: <MainCalendar />,
  [DashboardType.map]: <PharmacistMap />,
};

const { pharmacist } = TypeOfUser;
const { lg } = BreakPoints;

const Dashboard = () => {
  const theme = useTheme();
  const { name } = useUser();

  const lessThanLg = useMediaQuery(theme.breakpoints.down(lg));
  const { runTour, tourRef, handleJoyrideCallback } = useGuidelines();

  const activeDash = useAppSelector(getActiveDashboard);
  const typeOfUser = useAppSelector(getTypeOfUser);
  const canStartTour = useAppSelector(startTour);

  const { tour } = runTour;

  const isPharmacist = typeOfUser === pharmacist;

  // Using session storage here to check if user skipped the guideline and not to display it again until user reopens the app
  const isTempSkip = sessionStorage.getItem('tempSkip');
  const guidelineSteps = isPharmacist
    ? journeyStepsPharmacist()
    : journeyStepsPharmacy();

  const checkCanStartTour = tour && !isTempSkip;
  const initTour = checkCanStartTour || canStartTour;

  const toolbarTitle = (strings.formatString(strings.userDashboard, name ?? '') as string);

  useContinueCreation();
  useDashboard();
  useForegroundNotifications();

  return (
    <Grid container spacing={1}>
      {/* Guideline */}
      <Joyride
        steps={guidelineSteps}
        run={initTour}
        callback={handleJoyrideCallback}
        continuous
        ref={tourRef}
        tooltipComponent={GuideTooltip}
      />

      {/* main calendar */}
      <Grid item xs={12} lg={10}>
        <CustomDrawer />
        {lessThanLg && <LegendContainer row key={2} />}
        <ContentContainer>
          <Toolbar title={toolbarTitle} />
          {DashboardComponents[activeDash]}
        </ContentContainer>
      </Grid>

      {/* ads and legend */}
      <Grid sx={ResponsiveClasses.hideOnLg} item xs={2}>
        <Grid item sx={classes.legendGrid}>
          <LegendContainer key={1} />
        </Grid>
        <Grid item>
          <AdsContainer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
