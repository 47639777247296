import { SxProps, Theme } from '@mui/system';

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    p: 1,
  },
  panelContainer: {
    ml: 1,
    p: 1,
    pb: 0,
    minWidth: 126,
  },
  panelTitle: {
    fontSize: 16,
    fontWeight: '600',
    mb: 1,
  },
  panelList: {
    maxHeight: 280,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  panelListItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    borderRadius: '10px',
    mb: '2px',
    maxHeight: 30,
    minWidth: 110,
    px: 1,
  },
  panelListDate: {
    fontSize: 12,
    color: 'secondary.main',
    flex: 4,
    ml: '2px',
  },
  cancelIcon: {
    display: 'flex',
    flex: 1,
  },
} satisfies Record<string, SxProps<Theme>>;

export default classes;
