import { addGroupChannelMetadata } from '@pharmaplan/common';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useAppDispatch } from '../../useAppDispatch';

const ChatManager = () => {
  const dispatch = useAppDispatch();
  const store = useSendbirdStateContext();
  const sdk = store?.stores?.sdkStore?.sdk;

  const assignToCurrentUser = (userId: string, channel: string) => {
    sdk?.groupChannel?.getChannel(channel).then((inst) =>
      inst.updateChannel({ customType: userId }));

    dispatch(
      addGroupChannelMetadata({
        key: 'users',
        value: userId,
        channel,
      }),
    );
  };
  return { assignToCurrentUser };
};

export default ChatManager;
